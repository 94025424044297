import GithubLink from "../Links/GithubLink/GithubLink";
import PreviewLink from "../Links/PreviewLink/PreviewLink";
import styles from './ProjectSingle.module.scss';


const ProjectSingle = ({ title, description, img, link, github, tech, id }) => {

  if( id % 2 === 0 ){
  return (
    <div className={styles.wrapper}>
      <img className={styles.img} src={img} alt={title}></img>
      <div className={styles.description}>
        <h4>{title}</h4>
        <p>{description}</p>
        <div className={styles.tech}>{tech.map( tech => <p>{tech}</p>) }</div>
        <div>
          <GithubLink url={github} />
          <PreviewLink url={link} />
        </div>
      </div>
    </div>
  )
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <h4>{title}</h4>
          <p>{description}</p>
          <div className={styles.tech}>{tech.map( tech => <p>{tech}</p>) }</div>
          <div>
            <GithubLink url={github} />
            <PreviewLink url={link} />
          </div>
        </div>
        <img className={styles.img} src={img} alt={title}></img>
      </div>
    )
  }
}



export default ProjectSingle;